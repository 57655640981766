import Visible from 'src/components/helpers/Visible'

function Badge(props: {
  visible: boolean
  className?: string
  badgeClassName?: string
  count?: number | null
  absolute?: boolean
  children?: React.ReactNode
}) {
  const {
    className = '',
    badgeClassName = '',
    absolute = true,
    count = null,
    visible,
  } = props

  return (
    <div
      className={`relative ${
        absolute ? '' : 'flex flex-row justify-start items-center gap-2'
      } ${className}`}
    >
      {props.children}
      <Visible when={visible}>
        <CountItem
          count={count}
          absolute={absolute}
          badgeClassName={badgeClassName}
        />
      </Visible>
    </div>
  )
}

function CountItem(props: {
  absolute: boolean
  count?: number | null
  badgeClassName?: string
}) {
  const {absolute, count = null, badgeClassName = ''} = props

  return (
    <div className={absolute ? 'absolute -top-1 -right-1' : ''}>
      {count ? (
        <div
          className={`min-w-5 w-5 h-5 bg-primary-dark rounded-full flex flex-col justify-center items-center ${badgeClassName}`}
        >
          <span
            style={{color: 'white', fontSize: 11}}
            className=" font-semibold"
          >
            {count}
          </span>
        </div>
      ) : (
        <div
          className={`min-w-2 w-2 h-2 bg-primary-dark rounded-full ${badgeClassName}`}
        />
      )}
    </div>
  )
}

export default Badge
