import {useState} from 'react'
import {useRouter} from 'next/router'
import {useDispatch} from 'react-redux'
import {signOut} from 'next-auth/client'
import {useTranslation} from 'next-i18next'
import {makeStyles} from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import AppTypography from 'src/components/elements/typography/AppTypography'
import AppIcon from 'src/components/elements/icons/AppIcon'
import TopBar from './TopBar'
import MenuGroup from './MenuGroup'
import MenuItem from './MenuItem'
import {logoutAction} from 'src/store/actions/userAction'
import {
  useIsAdmin,
  useIsGuestMenu,
  useIsLoggedIn,
  useUserInfo,
} from 'src/hooks/user'
import {useCurrentLocale} from 'src/hooks/locale'
import {useAuthRequiredPage} from 'src/hooks/other'
import {useAppContext} from 'src/context/AppProvider'
import {
  GUEST_MENU_ITEMS,
  HOST_MENU_ITEMS,
} from 'src/components/modules/globals/header/data'
import {USER_ROLE_ADMIN} from 'src/constants/userRole'
import {
  ADMIN_ACCOUNT_PAGE,
  ADMIN_CALENDAR_PAGE,
  ADMIN_REVIEWS_PAGE,
  ADMIN_USERS_PAGE,
  HOME_PAGE,
} from 'src/constants/route'
import {useMenuContext} from 'src/context/MenuProvider'
import Hidden from 'src/components/helpers/Hidden'
import {useCanSwitchMenu, useHasAccess} from 'src/hooks/permissionAndAccess'
import Visible from 'src/components/helpers/Visible'
import {ACCESS_CALENDAR} from 'src/constants/collaboratorAccess'
import clsx from 'clsx'

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({spacing, palette, shadows}) => ({
  menuWrapper: {
    '& .MuiPopover-paper': {
      marginTop: spacing(1),
      boxShadow: shadows[2],
    },
  },
  popoverBox: {
    width: 263,
    padding: spacing(0, 2.5),
  },
  switchMenuButton: {
    borderRadius: spacing(3),
    justifyContent: 'center',
    cursor: 'pointer',
    padding: spacing(0.5, 0),
    backgroundColor: palette.grey[900],
    marginBottom: spacing(2),
  },
  accountTitle: {
    color: palette.common.white,
  },
  hostBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${palette.grey[200]}`,
    borderRadius: spacing(3),
    padding: spacing(1, 2),
    marginBottom: spacing(2),
    cursor: 'pointer',
    '& p': {
      color: palette.grey[600],
    },
  },
  logoutTitle: {
    color: `${palette.error.main} !important`,
    marginLeft: spacing(18 / 8),
  },
  changeLanguage: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${palette.grey[200]}`,
    borderRadius: spacing(3),
    padding: spacing(1, 2),
    cursor: 'pointer',
    marginBottom: spacing(2),
  },
}))

export default function ProfilePopUp(props: {isTop?: boolean}) {
  const {isTop} = props

  const canSwitchMenu = useCanSwitchMenu()
  const classes = useStyles()
  const isLoggedIn = useIsLoggedIn()
  const isAdmin = useIsAdmin()
  const isGuestMenu = useIsGuestMenu()
  const hasAccessCalendar = useHasAccess(ACCESS_CALENDAR)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)
  const handleCloseMenu = () => setAnchorEl(null)

  if (!isLoggedIn) {
    return null
  }

  return (
    <>
      <ProfileButton isTop={isTop} handleOpenMenu={handleOpenMenu} />
      <Menu
        className={classes.menuWrapper}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.popoverBox}>
          <MenuGroup>
            <MenuItem
              label="my_account"
              link={ADMIN_ACCOUNT_PAGE}
              icon="account"
              onClose={handleCloseMenu}
            />
            <MenuItem
              label="personal_information"
              link={`${ADMIN_ACCOUNT_PAGE}?page=personal`}
              icon="personalInfo"
              onClose={handleCloseMenu}
            />
          </MenuGroup>
          <MenuGroup>
            <MenuItems onClose={handleCloseMenu} />
            <Visible when={hasAccessCalendar && !isGuestMenu}>
              <MenuItem
                label="Calendar"
                link={ADMIN_CALENDAR_PAGE}
                icon="calendar_other"
                onClose={handleCloseMenu}
              />
            </Visible>
            <Visible when={isAdmin}>
              <MenuItem
                label="users"
                link={ADMIN_USERS_PAGE}
                icon="account"
                onClose={handleCloseMenu}
              />
            </Visible>
            <Visible when={isAdmin}>
              <MenuItem
                label="reviews"
                link={ADMIN_REVIEWS_PAGE}
                icon="booking"
                onClose={handleCloseMenu}
              />
            </Visible>
          </MenuGroup>
          <MenuGroup noBorder>
            <Hidden when={!canSwitchMenu}>
              <SwitchMenuButton />
            </Hidden>
            <ChangeLanguageButton handleCloseMenu={handleCloseMenu} />
            <LogoutButton onLogOut={handleCloseMenu} />
          </MenuGroup>
        </div>
      </Menu>
    </>
  )
}

function MenuItems(props: {onClose: () => void}) {
  const isGuestMenu = useIsGuestMenu()

  if (isGuestMenu) {
    return (
      <>
        {GUEST_MENU_ITEMS.map((item) => (
          <MenuItem
            onClose={props.onClose}
            key={item.link}
            label={item.label}
            link={item.link}
            icon={item.icon}
          />
        ))}
      </>
    )
  }

  return (
    <>
      {HOST_MENU_ITEMS.map((item) => (
        <MenuItem
          onClose={props.onClose}
          key={item.link}
          label={item.label}
          link={item.link}
          icon={item.icon}
        />
      ))}
    </>
  )
}

function ProfileButton(props: {
  isTop?: boolean
  handleOpenMenu: (event: React.MouseEvent<HTMLButtonElement>) => void
}) {
  const {isTop, handleOpenMenu} = props

  return <TopBar onClick={handleOpenMenu} isTop={isTop} />
}

function SwitchMenuButton() {
  const classes = useStyles()
  const {t} = useTranslation('header')
  const userInfo = useUserInfo()
  const isGuestMenu = useIsGuestMenu()
  const {toggleMenu} = useMenuContext()

  if (userInfo.role === USER_ROLE_ADMIN) {
    return null
  }

  return (
    <div
      className={clsx(classes.switchMenuButton, 'flex items-center')}
      onClick={toggleMenu}
    >
      <div className="flex flex-row justify-center items-center gap-3">
        <AppIcon name={isGuestMenu ? 'home_white' : 'guest_menu_white'} />
        <AppTypography variant="body" className={classes.accountTitle}>
          {t(isGuestMenu ? 'switch_to_host' : 'switch_to_guest')}
        </AppTypography>
      </div>
    </div>
  )
}

function ChangeLanguageButton(props: {handleCloseMenu: () => void}) {
  const {handleCloseMenu} = props

  const classes = useStyles()
  const {setModal} = useAppContext()
  const currentLocale = useCurrentLocale()

  const handleOpenChangeLanguageModal = () => {
    handleCloseMenu()
    setModal('languages')
  }

  return (
    <div
      className={classes.changeLanguage}
      onClick={handleOpenChangeLanguageModal}
    >
      <div className="flex flex-row justify-start items-center gap-3">
        <AppIcon name="canada" />
        <AppTypography variant="heading" neutralColor={600}>
          {currentLocale?.toUpperCase()}
        </AppTypography>
      </div>
      <AppIcon name="arrowRight" />
    </div>
  )
}

function LogoutButton(props: {onLogOut: () => void}) {
  const {onLogOut} = props

  const classes = useStyles()
  const dispatch = useDispatch()
  const router = useRouter()
  const {t} = useTranslation('header')
  const isAuthRequired = useAuthRequiredPage()

  const handleLogout = () => {
    dispatch(logoutAction())

    signOut({redirect: false})

    onLogOut()

    if (isAuthRequired) {
      router.push(HOME_PAGE)
    }
  }

  return (
    <div className={classes.hostBox} onClick={handleLogout}>
      <AppIcon name="logout" />
      <AppTypography variant="body" className={classes.logoutTitle}>
        {t('logout')}
      </AppTypography>
    </div>
  )
}
