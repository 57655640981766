export default function BarContainer(props: {
  children: React.ReactElement
  style?: React.CSSProperties
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}) {
  const {children, style, onClick} = props

  return (
    <button
      type="button"
      className="appearance-none flex flex-row justify-center items-center cursor-pointer border border-solid border-gray-200 rounded-md h-[40px] py-1 pl-2 pr-4"
      onClick={onClick}
      style={style}
    >
      {children}
    </button>
  )
}
